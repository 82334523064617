import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que es caracteritza per presentar unes taques rogenques o marró rogenques al peu accentuades a les espècies de muntanya. El peu és més aviat curt i gruixut. El capell, que taca molt les mans quan es toca, pot fer fins a 15 cm de diàmetre, de jove hemisfèric que a poc a poc es va obrint fins arribar a ser quasi pla. La coloració és blanquinosa cap al marge i presenta tons més o menys castanys segons l’edat. Presència de tubs curts i atapeïts que varien de color beix a grisenc. Quan el temps és humit, del porus que té pot sortir una mena de lletrada en forma de gotes rogenques. Les espores són de color ocre grogós fusiformes, de 8-10 x 3,5-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      